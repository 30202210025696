<template>
  <ion-page>
    <ion-header>
      <ion-toolbar class="sub_header">
        <ion-buttons slot="start">
          <ion-back-button default-href="/" text=""></ion-back-button>
        </ion-buttons>
        <ion-title>리워드(선물) 신청 방법</ion-title>
      </ion-toolbar>
    </ion-header>
    <ion-content :fullscreen="false">
      <div class="gift_method">
        <div class="gift_method_bg">
          <!-- gm_cont_01 -->
          <div class="gm_cont_01">
            <p class="ir_pm">
              리워드(선물) 신청은 이렇게 미션 도전에 완료 하셨군요! 스탬프 팝은
              총 2가지의 미션을 운영 중입니다.
              <span>
                배지미션 하단의 두 번째 메뉴룰 터치하면 미션 페이지로 이동
                합니다. 미션 페이지 상단의 "배지"를 터치하면 배지 미션들만
                확인할 수 있습니다. "배지" 미션은 스탬프 팝 에서 직접 제작한
                상징성 있는 배지를 제공함으로써 여행지에 대한 추억을 오래 간직해
                드리고자 하는 스탬프 팝의 따뜻한 마음이 담겨 있습니다.
                배지미션을 완료했을 경우 마이페이지 미션 완료 화면에서 확인이
                가능합니다.
              </span>
              <span>
                리워드 미션 리워드 미션은 해당 지역에서 스탬프를 획득한 분들께
                리워드를 제공해 드리는 미션입니다. 여행도 하고, 선물도 받고,
                일석이조의 미션이라고 하죠~ 라워드 미션을 완료했을 경우
                마이페이지 선물 화면에서 신청이 가능합니다.
              </span>
            </p>
          </div>
          <!-- //gm_cont_01 -->
        </div>

        <!-- gm_cont_02 -->
        <div class="gm_cont_02">
          <div class="gm_cont_02_text">
            <p>스탬프 팝을 이용하며</p>
            <p>세상에 하나뿐인 배지와 다양한 선물을 받아</p>
            <p>오래도록 기억에 남는 여행이 되길 바라요~</p>
          </div>
          <a href="#" @click.prevent.stop="onClose" class="go_link"
            >미션 도전하기</a
          >
        </div>
        <!-- //gm_cont_02 -->
      </div>
    </ion-content>
  </ion-page>
</template>
<style scoped lang="scss">
@import "./Mission-reward.scss";
</style>
<script >
import { IonPage, IonContent, IonToolbar, IonBackButton } from "@ionic/vue";
import { defineComponent, reactive,onMounted } from "vue";
import { useRouter } from "vue-router";
import { Analytics } from '../../../common/analytics';

export default defineComponent({
  components: { IonPage, IonContent, IonToolbar, IonBackButton },
  setup() {
    const router = useRouter();
    const screenName = "PAGE-PAGE_MISSION.REWARD";
      const analytics = new Analytics;
    const state = reactive({
      content: "##dsfd",
    });

    const onClose = () => {
      window.history.length > 1 ? router.go(-1) : router.push("/");
    };
    const goEvent = () => {
      router.push({
        name: "Event",
      });
    };

    onMounted(() => {
      analytics.setScreenName(screenName);
    })

    return { state, onClose, goEvent };
  },
});
</script>
